body:not(header) {
	& section {
		padding-top: $global-padding;
		padding-bottom: $global-large-padding;
		padding-left: $global-normal-padding;
		padding-right: $global-normal-padding;

		@include breakpoint(medium down) {
			padding-left: 15px !important;
			padding-right: 15px !important;
		}

		& p a {
			text-decoration: underline;
			color: get-color(secondary);
		}
	}

	.breadcrumbs {
		@include breakpoint(medium down) {
			padding-left: 15px !important;
			padding-right: 15px !important;
		}
	}
}

.accordion {
	& li {
		margin-bottom: 0;
	}
}

/* Buttons */
.button {
	text-transform: uppercase;
}

.button-center {
	margin: auto;
	display: block;
	width: 100%;
	max-width: 300px;
}

.button-spacing {
	margin-top: $global-normal-padding;
	margin-bottom: $global-large-padding;
}

/* --- Own Classes --- */
/* Spacer */
.spacer {
	padding: $global-padding 0;
}

.medium-spacer {
	padding: $global-big-padding 0;
}

.big-spacer {
	padding: $global-large-padding 0;

	@include breakpoint(medium down) {
		padding: $global-big-padding 0;
	}
}

/* Other */

.shadow {
	box-shadow: 7px 7px 30px rgba(0, 0, 0, 0.1);
}

@include breakpoint(large) {
	.overlapping-top {
		margin-top: -$global-xxlarge-padding;
		position: relative;
	}
}

.img-right {
	float: right;
	position: relative;
}

.clearfix {
	clear: both;
	display: inline-block;
}

.local-branding-day {
	background: url(/assets/img/local-branding-day.jpg);
	background-size: cover;
	background-position: center top;
	background-repeat: no-repeat;
	height: 650px;

	@include breakpoint(medium down) {
		height: 450px;
	}

	@include breakpoint(small down) {
		height: auto;
	}

	& .highlight {
		background: url(/assets/img/background_local-branding-day.png);
		background-size: contain;
		background-position: bottom left;
		background-repeat: no-repeat;

		padding: $global-large-padding $global-big-padding;
		margin-bottom: -$global-big-padding;

		& p,
		a {
			color: $white;
		}

		& p {
			font-size: rem-calc(48);
			line-height: rem-calc(52);
		}

		& a {
			font-size: rem-calc(36);
			line-height: rem-calc(40);
			display: inline-block;
			margin-left: rem-calc(60);

			&:before {
				content: "➞";
				display: inline-block;
				position: absolute;
				margin-left: rem-calc(-50);
				font-size: rem-calc(26);
			}
		}
	}
}

.logo-icon {
	@include breakpoint(medium) {
		&:before {
			content: "";
			position: absolute;
			align-self: flex-start;
			display: block;
			background: url(/assets/img/logo-icon.png);
			background-size: contain;
			background-repeat: no-repeat;
			height: 100%;
			width: 10vw;
			max-width: 70px;
		}
	}
}

.partner img:hover {
	cursor: pointer !important;
}

.map-yellow {
	background: get-color(primary);

	& iframe {
		filter: grayscale(1);
		opacity: 0.5;
	}
}

.past_lbd {
	& h4 {
		margin: rem-calc(15 0) !important;
	}
}

.lba-video {
    & h5 {
        color: get-color(primary);
    }

    & p {
        margin: rem-calc(15 0) !important;
        hyphens: auto;
    }
}

.ytp-embed .ytp-title-channel {
    display: none !important;
}