footer {
	& .newsletter {
		background-color: $light-gray;
		padding: 60px 0;

		& input {
			&:not([type="checkbox"]) {
				width: 100%;
				height: 100%;
				margin: 0;
			}

			&[type="email"] {
				@include breakpoint(small down) {
					font-size: 18px;
				}
			}
		}
	}

	& .copyright {
		padding: 12px 0;
		background-color: get-color(primary);
		color: $white;

		& a {
			color: $white;
			@include breakpoint(small down) {
				padding: 8px 20px;
			}
		}

		& p {
			margin-bottom: 0;
		}

		& .menu {
			@include breakpoint(medium down) {
				justify-content: flex-start;

				& li {
					text-align: left !important;

					& a {
						@include breakpoint(small only) {
                            padding-left: 0;
						}
					}

					&:first-child a {
						padding-left: 0;
					}
				}
			}
		}
	}
}
