.index {
    .galery {
        display: flex;
        flex-flow: wrap;
        align-items: center;
        justify-content: space-between;

        & img{
            max-width: 150px;
            margin: rem-calc(20);

            @media (max-width: 1200px) {
                max-width: 120px;
                margin: rem-calc(15);
            }

            @media (max-width: 990px) and (min-width: 875px) {
                max-width: 100px;
            }
        }
    }
}

.ueber-uns,
.mitglieder,
.gastgeber {
    .galery {
        display: flex;
        flex-flow: wrap;
        align-items: center;
        justify-content: space-between;

        & img{
            max-width: 225px;
            margin: rem-calc(20);

            @media (max-width: 1200px) {
                margin: rem-calc(15);
            }

            @media (max-width: 990px) {
                max-width: 175px;
            }
        }
    }
}

.gray {
    filter: grayscale(1);
}