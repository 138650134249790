body {
    font-size: 18px;
    letter-spacing: .8px;
}

a {
   &:hover {
       text-decoration: underline;
   } 
}

.menu {
    text-transform: lowercase;
}

@include breakpoint(medium) {
    h1 {
        line-height: rem-calc(43);
    }
}

h1, h2 {
    margin-bottom: $global-big-padding;
}

h3, h4 {
    line-height: rem-calc(43);
}

h4 {
    margin: 0;
}

main li {
        margin-bottom: .75rem;
}

table {
    & h1, h2, h3, h4, h5, h6, p {
        margin: 0;
    }
}

/* --- Own Classes --- */
.highlight-font {
    color: get-color(primary);
}

.big-font {
    font-size: rem-calc(100); 
    line-height: rem-calc(80); 
    letter-spacing: -7px;

    @media (max-width: 1150px) and (min-width: 1024px) {
        font-size:rem-calc(90);
    }

    @include breakpoint(medium down) {
        font-size: rem-calc(75); 
    }
}

.light-font {
    font-size: 15px;
    color: #c8c8c8;
}

.breadcrumbs {
    color: get-color(primary);
    text-transform: lowercase;
    font-size: rem-calc(15);
    font-weight: 300;
}

.text-margin-top {
    margin-top: rem-calc(-50);
    margin-bottom: rem-calc(20);
}

@include breakpoint(large) {
    .two-rows {
        column-count: 2;
        hyphens: auto; 
    }
}
