/* --- Own ID --- */
#vorteile {
	background: url(/assets/img/background_vorteile.png);
	background-repeat: no-repeat;
	background-position: center bottom;

	@include breakpoint(small down) {
		text-align: center;
	}
}

/* --- Classes --- */
.partner {
	background: url(/assets/img/background-partner.png);
	background-repeat: no-repeat;
	background-position: center right;

	padding: $global-big-padding 0;
}

.local-branding-alliance, 
.programm,
.anmeldung {
	& .back-1 {
		background: url(/assets/img/background_local-branding-alliance_1.png);
		background-repeat: no-repeat;
		background-position: top right;
	}

	& .back-2 {
		background: url(/assets/img/background_local-branding-alliance_2.png);
		background-repeat: no-repeat;
		background-position: center left;
	}

	& .back-3 {
		background: url(/assets/img/background_local-branding-alliance_3.png);
		background-repeat: no-repeat;
		background-position: center left;

		padding: $global-normal-padding;
	}
}

.ziele {
	& .back-1 {
		background: url(/assets/img/background-ziele.png);
		background-repeat: no-repeat;
		background-position: center top;
	}

	& .back-2 {
		background: url(/assets/img/background_local-branding-alliance_2.png);
		background-repeat: no-repeat;
		background-position: center right;
	}

	& .back-3 {
		background: url(/assets/img/background_vorteile.png);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
	}
}

.take-part {
	background: url(/assets/img/background_take-part.png);
	background-repeat: no-repeat;
	background-position: center right;
}

.reveal {
	background-image: url(/assets/img/background-partner.png);
	background-repeat: no-repeat;
	background-position: bottom right;
}
