header {
    padding: rem-calc(20);
    margin-bottom: rem-calc(20);

    /* Menü */
    & .menu a {
        color: get-color(secondary);
        padding: 0 20px;

        &:hover, &:focus {
            color: get-color(secondary);
            text-decoration: underline;
        }

        @include breakpoint(medium down) {
            padding: 12px 20px !important;
        }
    }

    & .menu li:first-child a {
        @include breakpoint(medium) {
            padding-left: 0;
        }
    }

    & #main-menu {
        @include breakpoint(medium down) {
            position: absolute;
            padding: rem-calc(20);
            background: $white;
            width: 100%;
            left: 0;
            z-index: 99999;
        }

        @include breakpoint(medium) {
            .is-dropdown-submenu-parent a {
                &::after {
                    display: none;
                }
            }
        }

        .sub-menu {
            position: absolute;
            margin-top: $global-padding;
            margin-left: rem-calc(89);
            z-index: 99999;

            @include breakpoint(medium down) {
                left: 0;
                margin-top: 0;
                background: $white;
                width: 100%;
                margin-left: 0;
                padding: 20px 0;
                padding-left: rem-calc(85);
            }

            & a {
                font-weight: 300;
                font-size: rem-calc(15);
                padding: 0 .75rem !important;
            }

            & li:first-child a {
                padding-left: 0 !important;
            }
        }
    }

    .menu-icon {
        width: 30px;
        height: 23px;

        &::after {
            height: 3px !important;
            box-shadow: 0 9px 0 #f9b200, 0 18px 0 #f9b200; 
        }
    }
}




