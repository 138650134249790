@include breakpoint(large) {
	.parallax {
		position: absolute;
	}

	.para_1 {
		top: 15rem;
        left: 5rem;
        
        @media (max-width: 1450px) {
            left: 1rem;
        }
	}

	.para_2 {
		top: 20rem;
        right: 5rem;
        
        @media (max-width: 1450px) {
            right: 1rem;
        }
	}

	.para_3 {
		top: 65rem;
        left: 0;
        
        @media (max-width: 1600px) {
            left: -5rem;
        }

        @media (max-width: 1450px) {
            left: -10rem;
        }

        @media (max-width: 1250px) {
            left: -15rem;
        }
	}

	.para_4 {
		top: 125rem;
        right: 5rem;
        
        @media (max-width: 1600px) {
            right: 0;
        }
	}

	.para_5 {
		top: 190rem;
        left: 1rem;
        
        @media (max-width: 1450px) {
            left: -5rem;
        }

        @media (max-width: 1250px) {
            left: -8rem;
        }
    }
}
