input {
    border: 1px solid $medium-gray;
    background-color: #fff;
}

[type="submit"] {
    text-transform: uppercase;
    color: $medium-gray;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 14px;
    padding: 25px;
    line-height: 14px;

    &:hover {
        color: get-color(primary);
    }
}

[type='checkbox'] {
    margin: 20px 20px 0 0;
} 

.checkbox {
    font-size: 15px;
    color: #c8c8c8;
}

textarea {
    height: auto;
    min-height: 250px;
}